<template>
    <v-menu dense>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="disabled"
                small
                icon
                v-bind="attrs"
                @click.stop="e => onClick(e, on)"
                :title="displayOptions[toggle].text">
                <v-icon :class="displayOptions[toggle].value == successValue ? 'success--text' : ''" small>{{ displayOptions[toggle].icon }}</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item-group
                v-model="toggle"
                @change="update">
                <template v-for="(f, i) in displayOptions">
                    <v-list-item :key="i">
                        <template v-slot:default="{ active }">
                            <v-list-item-action>
                                <v-icon v-if="active" small>mdi-check</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ f.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Toggle-Trigger-Icon',
    data: () => {
        return {
            toggle: 0
        }
    },    
    created() {
        if (this.value) {
            var ind = this.displayOptions.findIndex(x => x.value === this.value);
            if (ind >= 0) {
                this.toggle = ind;
            }
            else {
                this.toggle = this.displayOptions.findIndex(x => x.value === this.defaultValue);
            }
        }
    },
    props: {
        value: {
            Type: String,
            default: null
        },
        defaultValue: {
            type: [String, Boolean],
            default: 'Manual'
        },
        successValue: {
            type: [String, Boolean],
            default: 'Auto'
        },
        toggleOptions: {
            Type: Array,
            default: () => { return [
                { text: 'Manual', value: 'Manual', icon: 'mdi-boom-gate' }, 
                { text: 'Auto', value: 'Auto', icon: 'mdi-boom-gate-up' }] }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isScheduled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        displayOptions() {
            if (!this.isScheduled) {
                return this.toggleOptions
            }

            return [
                { text: 'Manual', value: 'Manual', icon: 'mdi-power-plug-off' }, 
                { text: 'Auto', value: 'Auto', icon: 'mdi-power-plug' }, 
                { text: 'Scheduled', value: 'Scheduled', icon: 'mdi-power-plug-off' }];
        }
    },
    methods: {
        onClick(ev, on) {
            if (this.displayOptions.length == 2) {
                this.toggle = this.toggle == 0 ? 1 : 0;
                this.update();
            }
            else {
                console.log(on);
                // console.log(callback);
                on.click(ev);
            }
        },
        update() {
            var v = this.displayOptions[this.toggle].value;
            this.$emit('input', v);
            this.$emit('change', v);
        }
    }
}
</script>